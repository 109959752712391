<template>
    <div class="corpARMoneyDetails">
        <div class="main-flex">
            <edit-btns class="btn-box" 
                :bnts="bnts"
                @queryClick="loadData()"  
                @qingzhangClick="qingzhangClick()"
                @zhuanzhangClick="zhuanzhangClick()"
                @buzhangClick="supplementIsShow=true"
                @yufukuanClick="rechargePayIsShow=true"
                @yufukuanhexiaoClick="yufukuanhexiaoClick()"
                @chexiaoClick="chexiaoClick()"
                @outputClick="exportExcel()" 
                @goBackClick="back()"/>
        </div>
        <div class="data-box">
            <div class="flex-box">
                <div class="filter-box">
                    <div class="from-box">
                        <div class="lable-txt">应收账户:</div>
                        <div class="from-input"><input class="name" type="text" v-model="Corp_Name" readonly/></div>
                    </div>
                    <div class="from-box">
                        <div class="lable-txt"><el-checkbox v-model="isDate" label="业务日期:"></el-checkbox> </div>
                        <div class="from-input">
                            <el-date-picker class="from-date"
                                v-model="businessHours"
                                type="daterange"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :default-time="defaultTime"
                            > </el-date-picker>
                        </div>
                    </div>
                </div>

                <tabs class="content-box clearfix">
                    <div class="nav-box-top">
                        <div class="nav-box" v-mouse-scroll >
                            <tab-nav class="nav-li">待清账挂账记录 <span class="num">{{filterData?.length||0}}</span></tab-nav>
                            <tab-nav class="nav-li">待核销收款记录 <span class="num">{{filterData2?.length||0}}</span></tab-nav>
                        </div>
                    </div>
                    <!--待清账挂账记录-->
                    <tab-pane>
                        <div class="table-box">
                            <table-vir style="width: 100%;height:100%" 
                                ref="tableEl"
                                border
                                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                                show-summary
                                @selection-change="selectTable"
                                :summary-method="getSummaries"
                                @cell-click="orderDetail"
                                :data="filterData"
                            > 
                                <table-column-vir label="选择" type="selection" width="30" ></table-column-vir>
                                <table-column-vir prop="Rpt_Date" label="业务日期" width="80"  :formatter="shortDateFormat"></table-column-vir>
                                <table-column-vir prop="Corp_Money" label="挂账金额" min-width="80" align="right" format="number" :formatter="priceFormat"></table-column-vir>
                                <table-column-vir prop="Pay_Money" label="已清金额" min-width="80" align="right" format="number" :formatter="priceFormat"></table-column-vir>
                                <table-column-vir prop="Surplus_Money" label="未结金额" min-width="80" align="right" format="number" :formatter="priceFormat"></table-column-vir>
                                <table-column-vir prop="Guarantor" label="担保人" min-width="80" align="left"></table-column-vir>
                                <table-column-vir prop="VoucherNo" label="结账单号" min-width="155" align="left">
                                    <template #default="scope">
                                        <span class="link">{{scope.row.VoucherNo}}</span>
                                    </template>
                                </table-column-vir>
                                <table-column-vir prop="Corp_Czy" label="操作人" min-width="80" align="left"></table-column-vir>
                                <table-column-vir prop="Corp_Time" label="挂账时间" width="130" :formatter="dateFormat"></table-column-vir>
                                <table-column-vir prop="Remark" label="备注" min-width="100" align="left"></table-column-vir>
                            </table-vir>
                        </div>
                    </tab-pane>
                    <!--待核销收款记录-->
                    <tab-pane>
                        <div class="table-box">
                            <table-vir style="width: 100%;height:100%" 
                                ref="tableEl2"
                                border
                                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                                show-summary
                                @selection-change="selectTable2"
                                :summary-method="getSummaries2"
                                :data="filterData2"
                            > 
                                <table-column-vir label="选择" type="selection" width="30" ></table-column-vir>
                                <table-column-vir prop="Rpt_Date" label="业务日期" width="80"  :formatter="shortDateFormat"></table-column-vir>
                                <table-column-vir prop="Pay_Money" label="预付金额" min-width="80" align="right" format="number" :formatter="priceFormat"></table-column-vir>
                                <table-column-vir prop="Check_Money" label="核销金额" min-width="80" align="right" format="number" :formatter="priceFormat"></table-column-vir>
                                <table-column-vir prop="Surplus_Money" label="待核销金额" min-width="80" align="right" format="number" :formatter="priceFormat"></table-column-vir>
                                <table-column-vir prop="Pay_Name" label="收款方式" min-width="100" align="left"></table-column-vir>
                                <table-column-vir prop="Corp_Czy" label="收款人" min-width="80" align="left"></table-column-vir>
                                <table-column-vir prop="Corp_Time" label="收款时间" width="130" :formatter="dateFormat"></table-column-vir>
                                <table-column-vir prop="Remark" label="备注" min-width="100" align="left"></table-column-vir>
                            </table-vir>
                        </div>
                    </tab-pane>
                </tabs>
            </div>
        </div>
        <!-- 转账 -->
        <modal-load :isShow="transInfoIsShow" >
            <trans-info-model :isShow="transInfoIsShow" :data="transInfo" @close="transInfoIsShow=false" @confirm="transInfoConfirm"></trans-info-model>
        </modal-load>
        <!-- 补账 -->
        <modal-load :isShow="supplementIsShow" >
            <supplement-model :isShow="supplementIsShow" 
                :data="{Corp_AutoID:Corp_AutoID,LastUpdated_Time:LastUpdated_Time}"
                @close="supplementIsShow=false" 
                @confirm="supplementConfirm"></supplement-model>
        </modal-load>
        <!-- 应收清账 -->
        <modal-load :isShow="payAccountsIsShow" >
            <pay-accounts-model :isShow="payAccountsIsShow" :data="payAccountsInfo" 
                :ZRZKPermission="ZRZKPermission"
                @updateTime="(time)=>{ updateTime(time,payAccountsInfo) ;}"
                @close="payAccountsIsShow=false" @confirm="qingzhangConfirm"></pay-accounts-model>
        </modal-load>
        <!-- 预付款 -->
        <modal-load :isShow="rechargePayIsShow" >
            <recharge-pay-model :isShow="rechargePayIsShow" 
                :data="{Corp_AutoID:Corp_AutoID,Corp_Name:Corp_Name,LastUpdated_Time:LastUpdated_Time}"
                @updateTime="(time)=>{LastUpdated_Time=time;}"
                @close="rechargePayIsShow=false" @confirm="rechargePayConfirm"></recharge-pay-model>
        </modal-load>
        <!-- 结账 报表弹层 -->
        <modal-load :isShow="orderIsShow" >
             <modal class="orderDetailModal" :isShow="orderIsShow" isIfShow>
                <div class="header">
                    订单信息
                    <div class="close-bnt" @click="orderIsShow=false"><i class="iconfont icon-guanbi"></i></div>
                </div>
                <div class="modal-body">
                    <bill-check-detail-report  :data="orderData" @close="orderIsShow=false"></bill-check-detail-report>
                </div>
             </modal>
        </modal-load>
    </div>
</template>

<script>
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import transInfoModel from './transInfoModel/transInfoModel.vue'
import supplementModel from './supplementModel/supplementModel.vue'
import payAccountsModel from './payAccountsModel/payAccountsModel.vue'
import rechargePayModel from './rechargePayModel/rechargePayModel.vue'
import billCheckDetailReport from '../../../report/reportModel/billCheckDetailReport/billCheckDetailReport.vue'
/**AR账单 应收账户明显 */
export default {
    name:"corpARMoneyDetails",
    components:{
        transInfoModel,
        supplementModel,
        payAccountsModel,
        rechargePayModel,
        billCheckDetailReport,
    },
    data(){
        return {
            /**控制按钮权限 */
            authorityBnts:{
                ARCLAERMONEY:false,//清账
                ARTRANSMONEY:false,//转账
                ARPREMONEY:false,//预付款
                ARPREMONEYCHECK:false,//预付款核销
                ARADD:false,//补账
                ARREF:false,//撤销
            },
            Corp_Name:"",
            Corp_AutoID:"",
            /**账户的最后更新时间  操作数据时用 */
            LastUpdated_Time:null,
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            isDate:false,
            /**待清账挂账记录 */
            tableData:[],
            /**待核销收款记录 */
            tableData2:[],
            /**待清账挂账记录 勾选*/
            checkTable:null,
            /**待核销收款记录 勾选*/
            checkTable2:null,
            /**转账 */
            transInfoIsShow:false,
            /**转账传入数据 */
            transInfo:null,
            /**补账 */
            supplementIsShow:false,
            /**应收清账 */
            payAccountsIsShow:false,
            /**应收清账传入数据 */
            payAccountsInfo:null,
            /**预付款 */
            rechargePayIsShow:false,
            /**结账 报表弹层 */
            orderIsShow:false,
            /**结账 传入数据 */
            orderData:null,
            //清账折扣权限
            ZRZKPermission:false
        }
    },
    computed:{
        bnts(){
            let bnts=['query','output','goBack']
            if(this.authorityBnts.ARCLAERMONEY){
                bnts.push('qingzhang')
            }
            if(this.authorityBnts.ARTRANSMONEY){
                bnts.push('zhuanzhang')
            }
            if(this.authorityBnts.ARPREMONEY){
                bnts.push('yufukuan')
            }
            if(this.authorityBnts.ARPREMONEYCHECK){
                bnts.push('yufukuanhexiao')
            }
            if(this.authorityBnts.ARADD){
                bnts.push('buzhang')
            }
            if(this.authorityBnts.ARREF){
                bnts.push('chexiao')
            }
            return bnts;
        },
        /**合计待清账挂账数据 */
        summaryTableList(){
            let total={
                Corp_Money:0,
                Pay_Money:0,
                Surplus_Money:0
            }
            this.filterData?.forEach((it)=>{
                total.Corp_Money+=it.Corp_Money;
                total.Pay_Money+=it.Pay_Money;
                total.Surplus_Money+=it.Surplus_Money;
            });
            return total;
        },
        /**合计待核销收款数据 */
        summaryTableList2(){
            let total={
                Pay_Money:0,
                Check_Money:0,
                Surplus_Money:0
            }
            this.filterData2?.forEach((it)=>{
                total.Pay_Money+=it.Pay_Money;
                total.Check_Money+=it.Check_Money;
                total.Surplus_Money+=it.Surplus_Money;
            });
            return total;
        },
        /**筛选 */
        filterData(){
            let data=this.tableData||[];
            if(this.isDate && this.businessHours.length==2){
                let beginDate=new Date(new Date(this.businessHours[0]).Format("yyyy-MM-dd 00:00:00"));
                let endDate=new Date(this.businessHours[1]);

                data=data.filter(it=>{
                    let date=new Date(it.Rpt_Date);
                    return date>=beginDate && date<endDate;
                });
            }
            return data;
        },
        /**筛选 */
        filterData2(){
            let data=this.tableData2||[];
            if(this.isDate && this.businessHours.length==2){
                let beginDate=new Date(new Date(this.businessHours[0]).Format("yyyy-MM-dd 00:00:00"));
                let endDate=new Date(this.businessHours[1]);

                data=data.filter(it=>{
                    let date=new Date(it.Rpt_Date);
                    return date>=beginDate && date<endDate;
                });
            }
            return data;
        }
    },
    watch:{
        pageSize(newVal){
            this.$cacheData.set("corpARMoney_pageSize",newVal);
        },
        tablePageSum(newVal){
            if(newVal<=this.tablePageIndex){
                this.tablePageIndex=0;
            }
        },
        searchTxt(){
            this.tablePageIndex=0;
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        let Rpt_Date=new Date(this.userInfo.Rpt_Date);
        this.businessHours=[Rpt_Date.Format("yyyy-MM-dd"),Rpt_Date.Format("yyyy-MM-dd")];
        this.Corp_Name=this.$route.params.Corp_Name;
        this.Corp_AutoID=this.$route.params.id;
        this.LastUpdated_Time=this.$route.params.LastUpdated_Time;
        this.loadData();

        /**报表菜单数据 */
        this.$cacheData.SysProjects?.get()?.find(t=>t.Project_Key=="Config")
        ?.SysProjectGroups.find(t=>t.Group_Key=='BaseSet_CloudPos' && t.SubGroup_YN)
        ?.SysProjectItems?.forEach(item => {
            item.SysProjectSubItems?.forEach(it=>{
                this.authorityBnts[it.ProjectSubItem_Key]=it.Permission;
                if(it.ProjectSubItem_Key=="ZRZK"){//"清账折扣权限"
                    this.ZRZKPermission=it.Permission;
                }
            })
        });
    },
    methods:{
        back(){
            this.$router.push('corpARMoney');
        },
        /**查询数据 */
        loadData(){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            return this.$httpAES.post("Bestech.CloudPos.GetARCorpConsumeInfo", {Corp_AutoID:this.Corp_AutoID}).then((d)=>{
                loading.close();
                
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    console.log(d)
                    this.tableData=d.ResponseBody?.ARCorpConsumes||[];//待清账挂账记录
                    this.tableData2=d.ResponseBody?.ARCorpMoneyInfos||[];//待核销收款记录
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        /*时间格式 */
        shortDateFormat(row, column, cellValue){
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd');
        },
        /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /**数据分页 */
        billPage(type){
            if(type==1){
                if(this.tablePageIndex+1<this.tablePageSum){
                    this.tablePageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.tablePageIndex==0){
                    this.$message.warning('已到第一页');
                }
                this.tablePageIndex>0?this.tablePageIndex--:this.tablePageIndex=0;
            }
        },
        /**导出excel */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            try {
                let ex1Data=this.$refs.tableEl.getExportData();
                let ws1=this.$excelCommon.addSheetCell(ex1Data);

                let ex2Data=this.$refs.tableEl2.getExportData();
                let ws2=this.$excelCommon.addSheetCell(ex2Data);

                var workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook,ws1,"待清账挂账记录");
                XLSX.utils.book_append_sheet(workbook, ws2, "待核销收款记录");
                var wbOut = XLSX.write(workbook, {
                    bookType: "xlsx",
                    bookSST: true,
                    type: "array"
                });
                FileSaver.saveAs(new Blob([wbOut], { type: "application/octet-stream" }),"应收账户"+this.Corp_Name+".xlsx");
            }catch(e){
                console.log(e, wbOut);
            }
        },
        //合计
        getSummaries({ columns, data }){
            const sums= [];
            let total=this.summaryTableList;
            columns.forEach((column, index) => {
                if(total[column.property]!=undefined){
                    sums[index]= parseFloat(total[column.property].toFixed(2));
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        },
        //合计
        getSummaries2({ columns, data }){
            const sums= [];
            let total=this.summaryTableList2;
            columns.forEach((column, index) => {
                if(total[column.property]!=undefined){
                    sums[index]= parseFloat(total[column.property].toFixed(2));
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        },
        //穿透
        orderDetail(row, column){
            if(column.property=="VoucherNo" && row.VoucherNo){
                this.orderData={
                    checkNo:row.VoucherNo,
                    Is_RedBill:row.IsRed
                }
                this.orderIsShow=true;
                console.log(row)
            }
        },
        /**勾选 */
        selectTable(currentRow){
            this.checkTable=currentRow;
        },
        /**勾选 */
        selectTable2(currentRow){
            this.checkTable2=currentRow;
        },
        /**转账 */
        zhuanzhangClick(){
            if(!this.checkTable || this.checkTable?.length==0){
                this.$message.warning('请选择需要转账的单据!');
                return;
            }
            let money=0
            this.checkTable.forEach(it=>{
                money+=it.Surplus_Money;
            })
            this.transInfo={
                Corp_AutoID:this.Corp_AutoID,
                Corp_Name:this.Corp_Name,
                LastUpdated_Time:this.LastUpdated_Time,
                money:money.toFixed(2),
                number:this.checkTable.length,
                Corp_ConsumeIDList:this.checkTable
            }
            this.transInfoIsShow=true;
        },
        /**转账 确认完成 */
        transInfoConfirm(deleList,LastUpdated_Time){
            this.tableData=this.tableData.filter(it=>deleList.indexOf(it.Corp_ConsumeID)<0);
            this.LastUpdated_Time=LastUpdated_Time
            this.transInfoIsShow=false;
        },
        /**补单 确认完成 */
        supplementConfirm(info,LastUpdated_Time){
            if(!this.tableData){
                this.tableData=[];
            }
            this.tableData.push(info);
            this.LastUpdated_Time=LastUpdated_Time;
            this.supplementIsShow=false;
        },
        /**点击 应收清账 */
        qingzhangClick(){
            if(!this.checkTable || this.checkTable?.length==0){
                this.$message.warning('请先选择需要清账的记录!');
                return;
            }
            let money=0
            this.checkTable.forEach(it=>{
                money+=it.Surplus_Money;
            })
            if(money<=0){
                this.$message.warning('没有需要清账的数据!');
                return;
            }
            this.payAccountsInfo={
                Corp_AutoID:this.Corp_AutoID,
                Corp_Name:this.Corp_Name,
                LastUpdated_Time:this.LastUpdated_Time,
                money:money.toFixed(2),
                number:this.checkTable.length,
                Corp_ConsumeIDList:this.checkTable
            }
            this.payAccountsIsShow=true;
        },
        /**应收清账 确认完成 */
        qingzhangConfirm(){
            this.payAccountsIsShow=false;
            this.loadData();
        },
        /**预付款  确认完成*/
        rechargePayConfirm(){
            this.rechargePayIsShow=false;
            this.loadData();
        },
        /**预付款核销 */
        yufukuanhexiaoClick(){
            if(!this.checkTable || this.checkTable?.length==0){
                this.$message.warning('请先选择待清账挂账记录！');
                return;
            }
            if(!this.checkTable2 || this.checkTable2?.length==0){
                this.$message.warning('请先选择待核销收款记录！');
                return;
            }

            let Surplus_Money=0,Surplus_Money2=0;
            let Corp_ConsumeIDList=[],Corp_MoneyIDList=[];
            this.checkTable.forEach(it=>{
                Surplus_Money+=it.Surplus_Money;
                Corp_ConsumeIDList.push(it.Corp_ConsumeID);
            })
            Surplus_Money=Number(Surplus_Money.toFixed(4));
            this.checkTable2.forEach(it=>{
                Surplus_Money2+=it.Surplus_Money;
                Corp_MoneyIDList.push(it.Corp_MoneyID);
            })
            Surplus_Money2=Number(Surplus_Money2.toFixed(4));
            let  text='您确认将账户【'+this.Corp_Name+'】预付款核销吗?<br/> 待清账金额:¥'+Surplus_Money+'元<br/>预付款金额:¥'+Surplus_Money2+'元'
            this.$confirm(text, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                dangerouslyUseHTMLString:true,
                type: 'info'
            }).then(() => {
                this.accountVerifyARCorpInfo({
                    Corp_ConsumeIDList:Corp_ConsumeIDList,//明细主键List
                    Corp_SumMoney:Surplus_Money,//总挂账金额
                    Corp_MoneyIDList:Corp_MoneyIDList,//预付款主键List
                    Pay_Money:Surplus_Money2,//总支付金额
                });
            });
        },
        /** 预付款核销 */
        accountVerifyARCorpInfo(data){
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.AccountVerifyARCorpInfo",{
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Corp_AutoID:this.Corp_AutoID,//原账户ID
                Corp_ConsumeIDList:data.Corp_ConsumeIDList,//明细主键List
                Corp_SumMoney:data.Corp_SumMoney,//总挂账金额
                Corp_MoneyIDList:data.Corp_MoneyIDList,//预付款主键List
                Pay_Money:data.Pay_Money,//总支付金额
                LastUpdated_Time:this.LastUpdated_Time,//原账户最后更新时间
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("预付款核销成功");
                    this.LastUpdated_Time=d.ResponseBody?.Corp_LastUpdateTime;
                    this.loadData();
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('预付款核销失败：'+e.message);
                console.log('预付款核销失败：',e);
            })
        },
        /**撤销 */
        chexiaoClick(){
            this.$router.push({
                name: 'chexiaoPayAccounts', 
                params:{
                    id:this.Corp_AutoID,
                    Corp_Name:this.Corp_Name,
                    LastUpdated_Time:this.LastUpdated_Time
                }
            }); 
        },
        /**更新时间戳 */
        updateTime(time,data){
            this.LastUpdated_Time=time;
            if(data){
                data.LastUpdated_Time=time;
            }
        }
    }
}
</script>

<style lang="scss">
    @import './corpARMoneyDetails.scss'
</style>